import React from 'react';
import { BookmarkFill, TagFill } from 'react-bootstrap-icons';
import { IIconProps, IconProps } from '../dm/icons.js';

const BiblePassageIcon = ({ size, color }: IIconProps) => {
  return <BookmarkFill height={size} width={size} fill={color} />;
};

const BibleVersionIcon = ({ size, color }: IIconProps) => {
  return <TagFill height={size} width={size} fill={color} />;
};

interface IPassageIcons extends IconProps {
  type: 'passage' | 'version';
}
export const PassageIcons = ({ size = '1em', color = 'currentColor', type }: IPassageIcons) => {
  const props: IIconProps = { size, color };

  switch (type) {
    case 'passage': {
      return BiblePassageIcon(props);
    }
    case 'version': {
      return BibleVersionIcon(props);
    }
  }
};
