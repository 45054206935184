import React, { HTMLAttributes, useMemo } from 'react';
import { ExclamationDiamondFill, InfoCircleFill, CheckSquareFill } from 'react-bootstrap-icons';
import { ToastType } from './types.js';

type IToastIcon = {
  type: ToastType;
} & HTMLAttributes<SVGElement>;
export const ToastIcon = ({ type, ...props }: IToastIcon) => {
  const icons = useMemo(
    () => ({
      [ToastType.Info]: <InfoCircleFill {...props} />,
      [ToastType.Danger]: <ExclamationDiamondFill {...props} />,
      [ToastType.Dark]: <InfoCircleFill {...props} />,
      [ToastType.Light]: <InfoCircleFill {...props} />,
      [ToastType.Primary]: <InfoCircleFill {...props} />,
      [ToastType.Secondary]: <InfoCircleFill {...props} />,
      [ToastType.Success]: <CheckSquareFill {...props} />,
      [ToastType.Warning]: <ExclamationDiamondFill {...props} />,
      [ToastType.None]: <InfoCircleFill {...props} />,
    }),
    [props]
  );

  const theIcon = useMemo(() => {
    return icons[type] || <InfoCircleFill {...props} />;
  }, [type, props, icons]);

  return theIcon;
};
